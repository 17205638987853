import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { WaterSupplier } from '@ui/water/interfaces/water-supplier';
import { getWaterSuppliersAction } from '@app/water/state/water.actions';
import { getWaterSuppliers } from '@app/water/state/water.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../../store/state';
import { WaterService } from '../services/water.service';

@Injectable({
    providedIn: 'root',
})
export class WaterSandbox {
    public waterSuppliers$: Observable<WaterSupplier[]> = this.store$.select(getWaterSuppliers);

    constructor(
        private store$: Store<State>,
        private waterService: WaterService
    ) {}

    public getWaterSuppliers(): void {
        this.store$.dispatch(getWaterSuppliersAction());
    }

    public confirmTransaction(transactionId: string): Observable<Move> {
        return this.waterService.confirmWaterProcess(transactionId);
    }
}
