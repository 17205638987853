import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { Move } from '@app/move/interfaces/move';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { setLogo } from '@app/real-estate-group/state/real-estate-group.actions';
import { State } from '@app/store/state';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { patchLoading } from '@app/ui/state/ui.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DbUtils, NoopAction, ObjectUtils } from '@smooved/core';
import { NotificationSandbox } from '@smooved/ui';
import { cloneDeep, get, isDate, isEqual, isNull, isObject, merge, set, values } from 'lodash';
import { iif, of, zip } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { MoveSandbox } from '../sandboxes/move.sandbox';
import { MoveService } from '../services/move.service';
import {
    Confirm,
    ConfirmEnergy,
    ConfirmEnergyFailure,
    ConfirmEnergySuccess,
    ConfirmFailure,
    ConfirmOffers,
    ConfirmOffersFailure,
    ConfirmOffersSuccess,
    ConfirmSuccess,
    CreateMove,
    CreateMoveFailure,
    CreateMoveForLeaver,
    CreateMoveForLeaverFailure,
    CreateMoveForLeaverSuccess,
    CreateMoveSuccess,
    DeleteMove,
    DontPatchMove,
    FetchMove,
    GetMoveByAccessTokenAndId,
    GetMoveByAccessTokenAndIdFailure,
    GetMoveByAccessTokenAndIdSuccess,
    MoveActionTypes,
    PatchMove,
    PatchMoveFailure,
    PatchMoveState,
    PatchMoveSuccess,
    PatchProperty,
    PatchPropertyFailure,
    PatchPropertySuccess,
    PatchRealEstateAgent,
    SetLatestMoveState,
    SetMoveState,
} from './move.actions';

@Injectable()
export class MoveEffects {
    public latestMoveValue: Move;

    constructor(
        private readonly store$: Store<State>,
        private readonly actions$: Actions,
        private readonly authenticationSandbox: AuthenticationSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly moveService: MoveService,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly activatedRoute: ActivatedRoute,
        private readonly matDialog: MatDialog,
        private readonly uiSandbox: AppUiSandbox,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public fetchMove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<FetchMove>(MoveActionTypes.FetchMove),
            concatMap((action) =>
                this.moveService.get(action.payload.moveId).pipe(
                    tap((move) => action.payload.callback?.(move)),
                    map((move) => new SetMoveState({ move }))
                )
            )
        )
    );

    public patchRealEstateAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PatchRealEstateAgent>(MoveActionTypes.PatchRealEstateAgent),
            concatMap((action) =>
                this.realEstateGroupSandbox.getLogo(action.payload).pipe(
                    map((response) => setLogo({ logo: response.logo })),
                    catchError((errorResponse) => of())
                )
            )
        )
    );

    public patchMoveState$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PatchMoveState>(MoveActionTypes.PatchMoveState, MoveActionTypes.PatchRealEstateAgent),
            concatMap((action) => zip(of(action), this.moveSandbox.moveOnce$, this.authenticationSandbox.isLoggedIn$.pipe(take(1)))),
            map(([action, move, isLoggedIn]) => {
                // when not logged in, or when we don't have an existing move, we can't patch the move
                if (!isLoggedIn || !move._id) {
                    return new DontPatchMove();
                }

                let patch;
                if (action.payload.propertyPath) {
                    patch = {
                        [action.payload.rootPath]: Object.assign(
                            {},
                            set({ ...move[action.payload.rootPath] }, action.payload.propertyPath, action.payload.value)
                        ),
                    };
                } else {
                    patch = {
                        [action.payload.rootPath]:
                            isObject(action.payload.value) && !isDate(action.payload.value)
                                ? { ...move[action.payload.rootPath], ...action.payload.value }
                                : action.payload.value,
                    };
                }

                const patchValues = values(patch);
                const patchValue = patchValues && patchValues.length && patchValues[0];
                const toCheck = get(this.latestMoveValue, action.payload.rootPath);

                const patchIsEmptyObject = isObject(patch) && !Object.keys(patch).length;
                const patchIsNull = isNull(patchValue);
                const patchIsEqualToLatestState = this.latestMoveValue && isEqual(toCheck, patchValue);

                if (patchIsEmptyObject || patchIsNull || patchIsEqualToLatestState) {
                    return new DontPatchMove();
                } else {
                    return new PatchMove({
                        id: move._id,
                        patch,
                        withNotification: false,
                    });
                }
            })
        )
    );

    public patchMove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PatchMove>(MoveActionTypes.PatchMove),
            tap(() => this.store$.dispatch(patchLoading({ loading: true }))),
            concatMap((action) => {
                const patch = ObjectUtils.cloneDeep(action.payload.patch);
                ObjectUtils.removeEmpty(patch, action.payload.forceNull);
                this.latestMoveValue = { ...this.latestMoveValue, ...patch };
                return this.moveService.patch(action.payload.id, patch).pipe(
                    tap((move) => {
                        if (action.payload.withNotification) {
                            this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
                        }
                        if (action.payload.dialogIdToClose) {
                            this.matDialog.getDialogById(action.payload.dialogIdToClose).close(true);
                        }
                        if (action.payload.callback) {
                            action.payload.callback(move);
                        }
                    }),
                    map(() => new PatchMoveSuccess()),
                    catchError((error) => {
                        if (action.payload.onFailure) action.payload.onFailure();
                        return of(
                            PatchMoveFailure({
                                error,
                                transactionId: action.payload.id,
                                request: patch,
                            })
                        );
                    })
                );
            })
        )
    );

    public patchMoveSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PatchMoveSuccess>(MoveActionTypes.PatchMoveSuccess),
            map(() => patchLoading({ loading: false }))
        )
    );

    public patchMoveFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MoveActionTypes.PatchMoveFailure),
            map(({ payload }) => patchLoading({ loading: false }))
        )
    );

    public patchProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PatchProperty>(MoveActionTypes.PatchProperty),
            tap((action) => {
                this.store$.dispatch(patchLoading({ loading: true }));
                if (action?.payload?.showLoadingOverlay) {
                    this.uiSandbox.showLoadingOverlay();
                }
            }),
            switchMap((action) =>
                zip(
                    of(action),
                    DbUtils.getStringId(action?.payload?.moveToPatch)
                        ? of(action.payload.moveToPatch).pipe(take(1))
                        : this.moveSandbox.moveOnce$
                ).pipe(take(1))
            ),
            switchMap(([action, moveState]) => {
                let patchedMoveState, difference;
                if (action.payload.checkMoveWithLatestState) {
                    patchedMoveState = { ...moveState };
                    difference = ObjectUtils.difference(moveState, this.latestMoveValue);
                } else {
                    patchedMoveState = MoveSandbox.createNewStateForPatch({ ...moveState }, { ...action });
                    difference = ObjectUtils.difference(patchedMoveState, moveState);
                }

                if (!difference || !Object.keys(difference).length) {
                    return of(
                        new PatchPropertySuccess({
                            callback: action.payload.callback,
                        })
                    );
                } else if (action.payload.persist && DbUtils.getStringId(moveState)) {
                    // todo - this is hack for updating move when guest ( EOTS confirm )
                    const accessToken: string = this.activatedRoute.snapshot.queryParams['at'];
                    return this.moveService.patch(moveState._id, difference, action.payload.bypassIsDraft, accessToken).pipe(
                        map((response) => {
                            return new PatchPropertySuccess({
                                newState: merge(cloneDeep(patchedMoveState), ObjectUtils.difference(response, patchedMoveState)),
                                callback: action.payload.callback,
                                persistToState: action.payload.persistToState,
                            });
                        }),
                        catchError((error) =>
                            of(
                                PatchPropertyFailure({
                                    error,
                                    transactionId: moveState._id,
                                    request: difference,
                                })
                            )
                        )
                    );
                } else {
                    return of(
                        new PatchPropertySuccess({
                            callback: action.payload.callback,
                            newState: patchedMoveState,
                            persistToState: action.payload.persistToState,
                        })
                    );
                }
            })
        )
    );

    public patchPropertySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<PatchPropertySuccess>(MoveActionTypes.PatchPropertySuccess),
                tap((action) => {
                    this.store$.dispatch(patchLoading({ loading: false }));
                    this.uiSandbox.hideLoadingOverlay();
                    if (action.payload?.callback) {
                        action.payload.callback(action.payload.newState);
                    }
                }),
                switchMap((action) =>
                    action.payload.persistToState ? of(new SetMoveState({ move: action.payload.newState as Move })) : of(action)
                ),
                finalize(() => this.uiSandbox.hideLoadingOverlay())
            ),
        { dispatch: false }
    );

    public patchPropertyFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MoveActionTypes.PatchPropertyFailure),
                tap((_) => {
                    this.store$.dispatch(patchLoading({ loading: false }));
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        { dispatch: false }
    );

    public patchPropertySuccessWithRealEstateAgent$ = createEffect(() =>
        this.actions$.pipe(
            ofType<PatchPropertySuccess>(MoveActionTypes.PatchPropertySuccess),
            concatMap((action) => zip(of(action), this.authenticationSandbox.isAdminOnce$, this.moveSandbox.moveOnce$)),
            concatMap(([action, isAdmin, move]) =>
                iif(
                    () =>
                        !isAdmin &&
                        !!action.payload.newState?.realEstateAgent &&
                        DbUtils.getStringId(action.payload.newState.realEstateAgent) !== DbUtils.getStringId(move.realEstateAgent),
                    this.realEstateGroupSandbox.logo$.pipe(
                        concatMap((_) => this.realEstateGroupSandbox.getLogo(DbUtils.getStringId(action.payload.newState.realEstateAgent))),
                        map((response) => setLogo({ logo: response.logo })),
                        catchError(() => of(new NoopAction()))
                    ),
                    of(new NoopAction())
                )
            )
        )
    );

    public setLatestMoveState = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SetLatestMoveState>(MoveActionTypes.SetLatestMoveState),
                tap((action) => (this.latestMoveValue = cloneDeep(action.payload.move)))
            ),
        { dispatch: false }
    );

    public createMove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<CreateMove>(MoveActionTypes.CreateMove),
            tap((_) => this.uiSandbox.moveLoading(true)),
            concatMap((action) => {
                const { move, options } = action.payload;
                delete move._id;
                ObjectUtils.buildPayload(move);

                return of(options?.mode).pipe(
                    mergeMap((mode) => (mode === 'single' ? this.moveService.registerSingle(move) : this.moveService.register(move))),
                    map((response) => {
                        return new CreateMoveSuccess({
                            id: response.id,
                            options: action.payload.options,
                        });
                    }),
                    catchError((errorResponse) => of(new CreateMoveFailure({ error: errorResponse, move })))
                );
            })
        )
    );

    public createMoveSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<CreateMoveSuccess>(MoveActionTypes.CreateMoveSuccess),
                tap((action) => {
                    this.uiSandbox.moveLoading(false);
                    if (action.payload.options?.clear) {
                        this.moveSandbox.clearMoveState();
                    } else {
                        this.moveSandbox.resetMoveState();
                    }
                    if (action.payload.options) {
                        if (action.payload.options.showNotification) {
                            this.notificationSandbox.success(NotificationLabel.MoveCreateSuccess);
                        }
                        if (action.payload.options.callback) {
                            action.payload.options.callback(action.payload.id);
                        }
                    }
                })
            ),
        { dispatch: false }
    );

    public createMoveFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<CreateMoveFailure>(MoveActionTypes.CreateMoveFailure),
                tap(({ payload }) => {
                    this.uiSandbox.moveLoading(false);
                })
            ),
        { dispatch: false }
    );

    public createMoveForLeaver$ = createEffect(() =>
        this.actions$.pipe(
            ofType<CreateMoveForLeaver>(MoveActionTypes.CreateMoveForLeaver),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            concatMap((action) => {
                const { move } = action.payload;
                const payload = ObjectUtils.cloneDeep(move);
                delete payload._id;
                ObjectUtils.buildPayload(payload);
                return this.moveService.registerForLeaver(payload).pipe(
                    map(
                        (response) =>
                            new CreateMoveForLeaverSuccess({
                                id: response.id,
                            })
                    ),
                    map(() => new FetchMove({ moveId: move.id, callback: action.payload.callback })),
                    catchError((errorResponse) => of(new CreateMoveForLeaverFailure()))
                );
            })
        )
    );

    public createMoveForLeaverSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<CreateMoveForLeaverSuccess>(MoveActionTypes.CreateMoveForLeaverSuccess),
                tap(() => {
                    this.uiSandbox.hideLoadingOverlay();
                    this.notificationSandbox.success(NotificationLabel.MoveCreateSuccess);
                })
            ),
        { dispatch: false }
    );

    public createMoveForLeaverFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<CreateMoveForLeaverFailure>(MoveActionTypes.CreateMoveForLeaverFailure),
                tap((_) => {
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        { dispatch: false }
    );

    public getByAccessToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetMoveByAccessTokenAndId>(MoveActionTypes.GetMoveByAccessTokenAndId),
            concatMap((action) => {
                const { accessToken, id, loadingIndication, callback } = action.payload;
                if (loadingIndication) {
                    this.uiSandbox.showLoadingOverlay();
                }
                return this.moveService.getByAccessTokenAndId(accessToken, id).pipe(
                    map(
                        (move) =>
                            new GetMoveByAccessTokenAndIdSuccess({
                                move,
                                callback,
                            })
                    ),
                    catchError((errorResponse) =>
                        of(
                            new GetMoveByAccessTokenAndIdFailure({
                                callback,
                            })
                        )
                    )
                );
            })
        )
    );

    public getByAccessTokenSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<GetMoveByAccessTokenAndIdSuccess>(MoveActionTypes.GetMoveByAccessTokenAndIdSuccess),
                tap((action) => {
                    this.uiSandbox.hideLoadingOverlay();
                    if (action.payload?.callback) {
                        action.payload.callback();
                    }
                })
            ),
        { dispatch: false }
    );

    GetMoveByAccessTokenAndIdFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<GetMoveByAccessTokenAndIdFailure>(MoveActionTypes.GetMoveByAccessTokenAndIdFailure),
                tap((action) => {
                    this.uiSandbox.hideLoadingOverlay();
                    if (action.payload?.callback) {
                        action.payload.callback();
                    }
                })
            ),
        { dispatch: false }
    );

    public confirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType<Confirm>(MoveActionTypes.Confirm),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            concatMap((action) => {
                return this.moveService
                    .confirm({
                        moveId: action.payload.id,
                        accessToken: action.payload.accessToken,
                    })
                    .pipe(
                        map(({ move }) => new ConfirmSuccess({ callback: action.payload.callback, move })),
                        catchError((errorResponse) => of(new ConfirmFailure()))
                    );
            })
        )
    );

    public confirmSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<ConfirmSuccess>(MoveActionTypes.ConfirmSuccess),
                tap((action) => {
                    this.uiSandbox.hideLoadingOverlay();
                    if (action.payload.callback) {
                        action.payload.callback();
                    }
                })
            ),
        { dispatch: false }
    );

    public confirmFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<ConfirmFailure>(MoveActionTypes.ConfirmFailure),
                tap((_) => {
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        { dispatch: false }
    );

    public confirmOffers$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConfirmOffers>(MoveActionTypes.ConfirmOffers),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            concatMap((action) => {
                return this.moveService.confirmOffers(action.payload.id, action.payload.accessToken).pipe(
                    map((_) => new ConfirmOffersSuccess({ callback: action.payload.callback })),
                    catchError((errorResponse) => of(new ConfirmOffersFailure()))
                );
            })
        )
    );

    public confirmOffersSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<ConfirmOffersSuccess>(MoveActionTypes.ConfirmOffersSuccess),
                tap((action) => {
                    this.uiSandbox.hideLoadingOverlay();
                    if (action.payload.callback) {
                        action.payload.callback();
                    } else {
                        this.navigationSandbox.goToOrderConfirmation().then();
                    }
                })
            ),
        { dispatch: false }
    );

    public confirmOffersFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<ConfirmOffersFailure>(MoveActionTypes.ConfirmOffersFailure),
                tap((_) => {
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        { dispatch: false }
    );

    public confirmEnergy$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConfirmEnergy>(MoveActionTypes.ConfirmEnergy),
            tap((_) => this.uiSandbox.showLoadingOverlay()),
            concatMap((action) => {
                return this.moveService.confirmEnergy(action.payload.accessToken, action.payload.id).pipe(
                    map((_) => new ConfirmEnergySuccess({ callback: action.payload.callback })),
                    catchError((errorResponse) => of(new ConfirmEnergyFailure()))
                );
            })
        )
    );

    public confirmEotsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<ConfirmEnergySuccess>(MoveActionTypes.ConfirmEnergySuccess),
                tap((action) => {
                    this.uiSandbox.hideLoadingOverlay();
                    if (action.payload.callback) {
                        action.payload.callback();
                    }
                })
            ),
        { dispatch: false }
    );

    public confirmEotsFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<ConfirmEnergyFailure>(MoveActionTypes.ConfirmEnergyFailure),
                tap((_) => {
                    this.uiSandbox.hideLoadingOverlay();
                })
            ),
        { dispatch: false }
    );

    public deleteMove$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteMove>(MoveActionTypes.DeleteMove),
            tap(() => this.uiSandbox.showLoadingOverlay()),
            concatMap((action) => {
                const { id, options } = action.payload;
                return this.moveService.delete(id, options).pipe(
                    map(() => {
                        this.uiSandbox.hideLoadingOverlay();
                        this.notificationSandbox.success('MOVE.DELETE.SUCCESS');
                        action.payload.callback();
                        return new NoopAction();
                    }),
                    catchError(() => {
                        this.uiSandbox.hideLoadingOverlay();
                        return of(new NoopAction());
                    })
                );
            })
        )
    );
}
