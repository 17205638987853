import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WaterSupplier } from '@ui/water/interfaces/water-supplier';
import { SvgIllustration, UiContext, UriSchemaType } from '@smooved/ui';

@Component({
    selector: 'app-water-supplier',
    templateUrl: './water-supplier.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterSupplierComponent {
    @Input() public waterSupplier: WaterSupplier;
    public readonly uriSchemaType = UriSchemaType;
    public readonly uiContext = UiContext;
    protected readonly svgIllustration = SvgIllustration;
}
