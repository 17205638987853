import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { validateZipCode } from '@app/form/validators/zip-code.validator';
import { ArrayUtils, RxjsComponent } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WaterInfoResponse } from '@ui/water/interfaces/water-info-response';
import { UiWaterService } from '@ui/water/services/water.service';

@Component({
    selector: 'app-lookup-tool-container',
    templateUrl: './lookup-tool.container.html',
})
export class LookupToolContainer extends RxjsComponent implements OnInit, OnDestroy {
    public loading = false;
    public readonly zipCodeControl = this.formBuilder.control(null, [Validators.required, validateZipCode]);
    public readonly subMunicipalityControl = this.formBuilder.control(
        {
            value: null,
            disabled: true,
        },
        [Validators.required]
    );
    public subMunicipalityOptions: DropdownInput<WaterInfoResponse>[] = [];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly uiWaterService: UiWaterService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.zipCodeControl.statusChanges
            .pipe(
                filter((zipCodeControlStatus) => zipCodeControlStatus === 'VALID'),
                tap((_) => {
                    this.loading = true;
                    this.subMunicipalityControl.patchValue(null);
                    this.subMunicipalityControl.disable();
                }),
                switchMap((status) => {
                    return this.uiWaterService.getWaterInfoByZipCode(this.zipCodeControl.value).pipe(
                        finalize(() => {
                            this.loading = false;
                        })
                    );
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((waterInfoResponse) => {
                this.subMunicipalityOptions = waterInfoResponse.map((waterInfo) => ({
                    id: waterInfo.subMunicipality,
                    value: waterInfo,
                    label: waterInfo.subMunicipality || waterInfo.municipality,
                    name: 'subMunicipality',
                }));
                if (ArrayUtils.isLength(this.subMunicipalityOptions, 1)) {
                    // preselect the only option when the length is 1
                    this.subMunicipalityControl.patchValue(ArrayUtils.first(this.subMunicipalityOptions).value);
                }
                if (!ArrayUtils.isEmpty(this.subMunicipalityOptions)) {
                    this.subMunicipalityControl.enable();
                }
            });
    }
}
