import { HttpErrorResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { isNumber } from 'lodash';
import { PaginationRequest } from '../interfaces';
import { ErrorCode, ErrorResponse } from '../interfaces/error-response.interface';
import { ObjectUtils } from './object-utils';

export class HttpUtils {
    public static addFiles(files: File[]): FormData {
        const formData = new FormData();
        if (!files) return formData;
        Array.from(files).forEach((file) => {
            formData.append('files', file, file['name']);
        });
        return formData;
    }

    public static addFile(file: File, propertyName = 'file'): FormData {
        const formData = new FormData();
        if (!file) return formData;
        formData.append(propertyName, file);
        return formData;
    }

    public static buildQueryParameters(query: Object, removeUndefined = false, removeEmptyString = false): HttpParams {
        let httpParams: HttpParams = new HttpParams();

        if (removeUndefined) ObjectUtils.removeEmpty(query, false, removeEmptyString);

        for (const key in query) {
            httpParams = httpParams.set(key, query[key]?.toString() || null);
        }

        return httpParams;
    }

    public static buildPaginationRequestObject(paginationRequest: PaginationRequest): PaginationRequest {
        const pagination: PaginationRequest = {};
        if (paginationRequest.sortDirection) {
            pagination.sortDirection = paginationRequest.sortDirection;
        }
        if (paginationRequest.sortField) {
            pagination.sortField = paginationRequest.sortField;
        }
        if (isNumber(paginationRequest.pageIndex)) {
            pagination.pageIndex = paginationRequest.pageIndex;
        }
        if (isNumber(paginationRequest.pageSize)) {
            pagination.pageSize = paginationRequest.pageSize;
        }
        return pagination;
    }

    public static buildPaginationRequest<T>(paginationRequest: PaginationRequest, extend?: T): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        if (!paginationRequest) return httpParams;

        if (paginationRequest.sortDirection) {
            httpParams = httpParams.set('sortDirection', paginationRequest.sortDirection);
        }

        if (paginationRequest.sortField) {
            httpParams = httpParams.set('sortField', paginationRequest.sortField);
        }

        if (isNumber(paginationRequest.pageIndex)) {
            httpParams = httpParams.set('pageIndex', paginationRequest.pageIndex?.toString() || null);
        }

        if (isNumber(paginationRequest.pageSize)) {
            httpParams = httpParams.set('pageSize', paginationRequest.pageSize?.toString() || null);
        }

        if (ObjectUtils.isObject(extend)) {
            Object.entries(extend).map(([key, value]) => {
                httpParams = httpParams.set(key, value);
            });
        }

        return httpParams;
    }

    public static setHeaders(
        request: HttpRequest<any>,
        headers: {
            [name: string]: string | string[];
        }
    ): HttpRequest<any> {
        return request.clone({
            setHeaders: headers,
        });
    }

    public static getErrorCode(errorResponse: HttpErrorResponse | ErrorResponse): ErrorCode {
        const { error } = errorResponse;
        return ObjectUtils.isObject(error.errorCode)
            ? (error.errorCode as ErrorCode)
            : {
                  message: (error.errorCode as string) || ((errorResponse as ErrorResponse).errorCode as string),
              };
    }
}
