import { NgModule } from '@angular/core';
import { AddressModule } from '@app/address/address.module';
import { DocumentCenterModule } from '@app/document-center/document-center.module';
import { LookupToolContainer } from '@app/water/containers/lookup-tool/lookup-tool.container';
import { WaterEffects } from '@app/water/state/water.effects';
import { waterReducer } from '@app/water/state/water.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AlertModule, InfoModule, SvgModule } from '@smooved/ui';
import { CardModule } from '../card/card.module';
import { ModalModule } from '../modal/modal.module';
import { SharedModule } from '../shared/shared.module';
import { WaterSupplierComponent } from './components/water-supplier/water-supplier.component';
import { WaterDocumentMovingAddressModal } from './modals/water-document-moving-address/water-document-moving-address.modal';
import { WaterRoutingModule } from './water-routing.module';
import { WaterProcessAttachmentsModal } from '@app/water/modals/water-process-attachments/water-process-attachments.modal';
import { FileUploadModule } from 'ng2-file-upload';
import { WaterTransferAssetFilterPipe } from '@app/water/pipe/water-transfer-asset-filter.pipe';
import { WaterMeterReadingDetailsComponent } from '@app/water/components/water-meter-reading-details/water-meter-reading-details.component';
import { UiWaterModule } from '@ui/water/water.module';

@NgModule({
    declarations: [
        LookupToolContainer,
        WaterMeterReadingDetailsComponent,
        WaterDocumentMovingAddressModal,
        WaterSupplierComponent,
        WaterProcessAttachmentsModal,
        WaterTransferAssetFilterPipe,
    ],
    imports: [
        CardModule,
        WaterRoutingModule,
        SharedModule,
        ModalModule,
        SvgModule,
        DocumentCenterModule,
        StoreModule.forFeature('water', waterReducer),
        EffectsModule.forFeature([WaterEffects]),
        InfoModule,
        AlertModule,
        AddressModule,
        FileUploadModule,
        UiWaterModule,
    ],
    exports: [
        WaterDocumentMovingAddressModal,
        WaterProcessAttachmentsModal,
        WaterTransferAssetFilterPipe,
        WaterMeterReadingDetailsComponent,
    ],
})
export class WaterModule {}
