import { WaterSupplier } from '@ui/water/interfaces/water-supplier';
import { DbUtils } from '@smooved/core';
import { DropdownInput } from '@smooved/ui';

export enum WaterDetailFields {
    WaterSupplier = 'waterSupplier',
    NationalRegistrationNumber = 'nationalRegistrationNumber',
    ClientNumber = 'clientNumber',
    MeterNumber = 'meterNumber',
    MeterReading = 'meterReading',
    WaterTransferDocument = 'waterTransferDocument',
    MovingDate = 'movingDate',
}

export interface WaterDetailForm {
    [WaterDetailFields.WaterSupplier]: string;
    [WaterDetailFields.NationalRegistrationNumber]: string;
    [WaterDetailFields.ClientNumber]: string;
    [WaterDetailFields.MeterNumber]: string;
    [WaterDetailFields.MeterReading]: number;
}

export const waterSupplierOptionsFactory = ({ name, ...rest }: WaterSupplier): DropdownInput<string> => ({
    id: `supplier-${name}`,
    name: 'supplier',
    value: DbUtils.getStringId(rest),
    label: name,
});

export const waterSupplierURI = {
    nl: 'https://callmepower.be/nl/water/watermaatschappij',
    fr: 'https://callmepower.be/fr/eau/distributeurs',
    en: 'https://callmepower.be/nl/water/watermaatschappij',
};

export const requiredLabel = '<span class="u-color-danger"> *</span>';

export const placeholders = {
    [WaterDetailFields.NationalRegistrationNumber]: '11.22.33-123.45',
    [WaterDetailFields.ClientNumber]: '123456789',
    [WaterDetailFields.MeterNumber]: '123456789',
    [WaterDetailFields.MeterReading]: '123456789',
};
