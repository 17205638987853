import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-suggestion-selection-water',
    template: `
        <table>
            <tr>
                <td class="u-vertical-align-middle u-padding-right-half u-text-align-right" *ngIf="uiSandbox.tabletPortraitUp$ | async">
                    <img src="assets/images/smooved-water.png" width="50px" class="__image u-display-block" />
                </td>

                <td class="u-vertical-align-middle u-w100p">
                    <div class="u-flex-column u-flex-align-items-start">
                        <h5>{{ 'WATER.LABEL' | translate }}</h5>
                        <p
                            class="u-color-muted u-font-size-small"
                            *ngIf="moveSandbox.waterInfoCurrentAddress$ | async as waterInfoCurrentAddress"
                        >
                            {{ 'WATER.ADDRESSES.CURRENT_ADDRESS_SELECTED.LABEL' | translate }}
                            :
                            <a
                                *ngFor="let waterSupplier of waterInfoCurrentAddress.waterSuppliers"
                                class="u-link"
                                [href]="'assets/water/documents/' + waterSupplier.name + '.pdf'"
                                download
                                >{{ waterSupplier.name }}</a
                            >
                        </p>
                        <p
                            class="u-color-muted u-font-size-small"
                            *ngIf="moveSandbox.waterInfoMovingAddress$ | async as waterInfoMovingAddress"
                        >
                            {{ 'WATER.ADDRESSES.MOVING_ADDRESS_SELECTED.LABEL' | translate }}
                            :
                            <a
                                *ngFor="let waterSupplier of waterInfoMovingAddress.waterSuppliers"
                                class="u-link"
                                [href]="'assets/water/documents/' + waterSupplier.name + '.pdf'"
                                download
                                >{{ waterSupplier.name }}</a
                            >
                        </p>
                    </div>
                </td>
            </tr>
        </table>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionSelectionWaterComponent {
    constructor(
        public moveSandbox: MoveSandbox,
        public uiSandbox: AppUiSandbox
    ) {}
}
