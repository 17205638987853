<app-loading-container [loading]="loading">
    <div
        class="u-flex-row u-padding-half u-padding-right-double u-border-radius u-bordered-gray-lightest u-margin-bottom-half u-flex-align-items-center __attachment-row"
        *ngFor="let waterTransferAsset of move?.water?.waterTransferAssets"
    >
        <smvd-ui-svg-illustration
            class="u-margin-right u-flex-no-shrink __mime-icon"
            [svg]="waterTransferAsset.mime === Mimetypes.Pdf ? SvgIllustration.PdfFile: SvgIllustration.ImageFile"
        ></smvd-ui-svg-illustration>
        <div class="u-flex-column u-flex-grow-1 u-cursor-pointer u-min-w-0" (click)="onWaterTransferAssetDetail(waterTransferAsset)">
            <div class="u-color-gray-ultradark __file-name">{{ waterTransferAsset?.name }}</div>
            <div class="u-font-size-12 u-color-gray-dark">{{ waterTransferAsset?.createdOn | formatDate }}</div>
        </div>
        <div
            class="u-cursor-pointer u-flex-row u-flex-align-items-center"
            (click)="toggleAssetType(waterTransferAsset,WaterTransferAssetType.EmailAttachment)"
            appTooltip
            [template]="markAttachmentTooltipTemplate"
        >
            <smvd-ui-svg-illustration class="u-margin-right-xs" [svg]="SvgIllustration.PaperClip"></smvd-ui-svg-illustration>
            <mat-checkbox
                (click)="$event.stopPropagation(); $event.preventDefault()"
                disabled
                [class.--checkbox-disabled]="waterTransferAsset?.waterTransferAssetType === WaterTransferAssetType.TransferDocument"
                [checked]="waterTransferAsset?.waterTransferAssetType === WaterTransferAssetType.EmailAttachment || waterTransferAsset?.waterTransferAssetType === WaterTransferAssetType.TransferDocument"
            ></mat-checkbox>
        </div>
        <div
            class="u-cursor-pointer u-flex-row u-flex-align-items-center u-margin-left"
            (click)="toggleAssetType(waterTransferAsset, WaterTransferAssetType.TransferDocument);"
            appTooltip
            [template]="markTransferDocumentTooltipTemplate"
        >
            <smvd-ui-svg-illustration class="u-margin-right-xs" [svg]="SvgIllustration.Document"></smvd-ui-svg-illustration>
            <mat-checkbox
                (click)="$event.preventDefault()"
                [disableRipple]="true"
                disabled
                [checked]="waterTransferAsset?.waterTransferAssetType === WaterTransferAssetType.TransferDocument"
            ></mat-checkbox>
        </div>
    </div>

    <div class="u-flex-row u-margin-y-axis-double">
        <div
            class="u-flex-grow-1 u-bordered u-cursor-pointer u-color-gray-ultradark u-background-gray-ultralight u-padding-x-axis u-padding-y-axis-half u-flex-column u-flex-align-items-start u-mw50p __attachment-button"
            (click)="fileInput.click()"
        >
            <app-svg-illustration [svg]="svgIllustration.Upload" class="__upload-icon"></app-svg-illustration>
            {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.PROCESS.UPLOAD_ATTACHMENTS' | translate }}
        </div>
        <div class="u-flex-column u-flex-grow-1 u-mw50p">
            <div
                class="u-flex-grow-1 u-bordered u-cursor-pointer u-color-gray-ultradark u-background-gray-ultralight u-margin-left-half u-padding-x-axis u-padding-y-axis-half u-flex-column u-flex-align-items-start __attachment-button"
                [class.u-disabled]="!move?.water?.waterSupplier"
                (click)="move?.water?.waterSupplier && onGenerateWaterTransferDocument()"
            >
                <app-svg-illustration [svg]="illustrationEnum.Settings" [context]="UiContext.PrimaryDark"></app-svg-illustration>
                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.PROCESS.GENERATE_TRANSFER_DOCUMENT' | translate }}
            </div>
            <div *ngIf="!move?.water?.waterSupplier" id="no-supplier-selected" class="u-margin-left-half u-color-warning">
                {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.PROCESS.NO_SUPPLIER' | translate }}
            </div>
        </div>
    </div>

    <div class="u-flex-row u-flex-justify-content-flex-end">
        <button smvd-ui-button [appearance]="buttonAppearance.Stroked" [context]="UiContext.PrimaryDark" (click)="cancel()">
            {{ 'CANCEL' | translate }}
        </button>
        <button app-button class="u-margin-left-half" (click)="saveAssetTypeMapping()" [context]="UiContext.PrimaryDark">
            {{ 'SAVE' | translate }}
        </button>
    </div>
</app-loading-container>

<input
    #fileInput
    hidden
    [accept]="ALLOWED_MIME_TYPES"
    type="file"
    ng2FileSelect
    (onFileSelected)="onFileSelected($event)"
    [uploader]="uploader"
    multiple
/>

<ng-template #markAttachmentTooltipTemplate>
    <p class="u-font-size-xs u-font-weight-normal">{{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.PROCESS.MARK_ATTACHMENT' | translate }}</p>
</ng-template>

<ng-template #markTransferDocumentTooltipTemplate>
    <p class="u-font-size-xs u-font-weight-normal">
        {{ 'ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.PROCESS.MARK_TRANSFER_DOCUMENT' | translate }}
    </p>
</ng-template>
