import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveService } from '@app/move/services/move.service';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { WaterTransferAssetDetailComponent } from '@app/real-estate-agent/modals/water-transfer-asset-detail/water-transfer-asset-detail.component';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { WaterTransferAssetType } from '@ui/water/enums/water-transfer-asset-type.enum';
import { Water, WaterTransferAsset } from '@app/water/interfaces/water';
import { WaterProcessAttachmentsModal } from '@app/water/modals/water-process-attachments/water-process-attachments.modal';
import { WaterSandbox } from '@app/water/sandboxes/water.sandbox';
import { WaterService } from '@app/water/services/water.service';
import { TranslateService } from '@ngx-translate/core';
import {
    AddressUtils,
    ALLOWED_MIME_TYPES,
    ArrayUtils,
    Asset,
    DbUtils,
    EnergyMetersState,
    FileUtils,
    I18nKeyService,
    MAX_FILE_SIZE,
    Mimetypes,
    ObjectUtils,
    RxjsComponent,
} from '@smooved/core';
import { ButtonAppearance, ModalSandbox, NotificationSandbox, SvgIllustration, UiContext, UiSize } from '@smooved/ui';
import { BehaviorSubject, catchError, combineLatest, Observable, of, startWith, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { placeholders, requiredLabel, WaterDetailFields, waterSupplierOptionsFactory, waterSupplierURI } from './water-details.constants';

@Component({
    selector: 'smvd-app-water-details-modal',
    templateUrl: 'water-details.modal.html',
    styleUrls: ['./water-details.modal.scss'],
})
export class WaterDetailsModalComponent extends RxjsComponent implements OnInit, AfterViewInit {
    @Input() public move: Move;

    const;
    public lookupToolUrl = '/water/lookup-tool';

    public loading = false;
    public uploader;

    private addedAssetKeys: string[] = [];

    public readonly form = this.fb.group({
        [WaterDetailFields.WaterSupplier]: [null],
        [WaterDetailFields.NationalRegistrationNumber]: null,
        [WaterDetailFields.ClientNumber]: null,
        [WaterDetailFields.MeterNumber]: [null],
        [WaterDetailFields.MeterReading]: [null],
    });
    public readonly requiredLabel = requiredLabel;
    public readonly placeholders = placeholders;
    public readonly waterSupplierURI = waterSupplierURI[this.translateService.currentLang] as string;
    public readonly waterDetailFields = WaterDetailFields;
    public readonly waterSupplierOptions$ = this.waterSandbox.waterSuppliers$.pipe(
        map((suppliers) => suppliers?.map(waterSupplierOptionsFactory))
    );
    public canConfirmMeters$: Observable<boolean>;

    public readonly context = UiContext;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly SvgIllustration = SvgIllustration;
    public readonly Mimetypes = Mimetypes;
    public readonly EnergyMetersState = EnergyMetersState;
    public readonly svgIllustration = SvgIllustration;
    public readonly WaterTransferAssetType = WaterTransferAssetType;
    public readonly ALLOWED_MIME_TYPES = ALLOWED_MIME_TYPES;
    public readonly uiContext = UiContext;

    private filesChangedSubject = new Subject<void>();
    private readonly isConfirmedSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly dialogRef: MatDialogRef<any>,
        private readonly modalSandbox: ModalSandbox,
        private readonly fb: FormBuilder,
        private readonly waterSandbox: WaterSandbox,
        private readonly translateService: TranslateService,
        private readonly moveService: MoveService,
        private readonly moveSandbox: MoveSandbox,
        private readonly waterService: WaterService,
        private readonly appUiSandbox: AppUiSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly i18nKeyService: I18nKeyService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.move?.water) {
            const { waterSupplier, nationalRegistrationNumber, clientNumber, meterNumber, meterReading } = this.move.water;
            this.form.patchValue({
                waterSupplier: DbUtils.getStringId(waterSupplier),
                nationalRegistrationNumber,
                clientNumber,
                meterNumber,
                meterReading,
            });
        }
        this.isConfirmedSubject.next(this.move?.water.waterTransferConfirmedByRealEstateAgent);
        this.waterSandbox.getWaterSuppliers();
        this.uploader = FileUtils.getFileUploader();
        this.handleAssetsInit();
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.isConfirmedSubject.next(false));
        this.canConfirmMeters$ = combineLatest([
            this.form.valueChanges.pipe(startWith(this.form.value)),
            this.filesChangedSubject.asObservable(),
        ]).pipe(
            debounceTime(0),
            map(([{ waterSupplier, meterNumber, meterReading }, _]) => {
                return !!waterSupplier && ((!!meterReading && !!meterNumber) || !ArrayUtils.isEmpty(this.move.water?.waterTransferAssets));
            })
        );
        this.dialogRef.afterClosed().subscribe((move) => {
            if (!move && !ArrayUtils.isEmpty(this.addedAssetKeys)) {
                this.waterService.updateWaterTransferAssets(DbUtils.getStringId(this.move), this.addedAssetKeys, []).subscribe();
            }
        });
    }

    public ngAfterViewInit(): void {
        this.filesChangedSubject.next();
    }

    private handleAssetsInit(): void {
        this.moveSandbox.moveOnce$
            .pipe(
                map((move) => {
                    const uploadedAssets = [...(move.water?.waterTransferAssets ?? [])] as unknown as File[];
                    this.uploader.addToQueue(uploadedAssets);
                })
            )
            .subscribe();
    }

    public onFileSelected(fileList: FileList): void {
        const list = Array.from(fileList);
        const maxSize = list.find((file) => file.size > FileUtils.convertMegaBytesToBytes(MAX_FILE_SIZE));
        if (maxSize) {
            this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.SIZE', { maxFileSize: MAX_FILE_SIZE });
            return;
        }

        const noAllowedType = list.find((file) => !ALLOWED_MIME_TYPES.includes(file.type as Mimetypes));
        if (noAllowedType) {
            this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.TYPE', {
                allowedTypes: ALLOWED_MIME_TYPES.map((mime) => ArrayUtils.last(mime.split('/'))).join(', '),
            });
            return;
        }

        this.form.markAsDirty();
        this.loading = true;
        this.waterService
            .updateWaterTransferAssets(DbUtils.getStringId(this.move), [], list, WaterTransferAssetType.Attachment)
            .subscribe((updatedMove) => {
                this.move = updatedMove;
                list.forEach((file) =>
                    this.addedAssetKeys.push(updatedMove.water.waterTransferAssets.find((asset) => asset.name === file.name).key)
                );
                this.filesChangedSubject.next();
                this.isConfirmedSubject.next(false);
                this.loading = false;
            });
    }

    public removeAsset(waterTransferAsset: WaterTransferAsset): void {
        this.loading = true;
        this.form.markAsDirty();
        this.waterService
            .updateWaterTransferAssets(DbUtils.getStringId(this.move), [waterTransferAsset.key], [], WaterTransferAssetType.Attachment)
            .subscribe((updatedMove) => {
                this.move = updatedMove;
                this.filesChangedSubject.next();
                this.loading = false;
            });
    }

    public saveAndConfirmWaterDetails(): void {
        const config = {
            header: this.i18nKeyService.instant(
                'DETAIL.WATER.TRANSFER.COMPLETED.CONFIRM_MODAL.HEADER',
                appI18nKeyTypes.RealEstateAgentDashboard
            ),
            question: this.i18nKeyService.instant(
                'DETAIL.WATER.TRANSFER.COMPLETED.CONFIRM_MODAL.INFO',
                appI18nKeyTypes.RealEstateAgentDashboard
            ),
        };
        this.modalSandbox.openConfirmModal(config, this.confirmHandler(), config, this.confirmHandler());
    }

    private confirmHandler(): (confirm: boolean) => void {
        return (confirm) => {
            if (!confirm) return;
            this.saveWaterDetails(true);
        };
    }

    public saveWaterDetails(confirm?: boolean): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) return;

        this.loading = true;

        this.moveService.editWaterMeterReadings(DbUtils.getStringId(this.move), this.form.value as Water).subscribe((move) => {
            if (confirm) {
                this.waterSandbox
                    .confirmTransaction(DbUtils.getStringId(move))
                    .pipe(catchError((_) => of(null)))
                    .subscribe((move) => {
                        this.loading = false;
                        this.appUiSandbox.hideLoadingOverlay();
                        this.dialogRef.close(move);
                    });
            } else {
                this.loading = false;
                this.appUiSandbox.hideLoadingOverlay();
                this.dialogRef.close(move);
            }
        });
    }

    public onEnergyMeterReadingDetail(file: FileInput): void {
        this.openModal(WaterTransferAssetDetailComponent, this.modalDataFactory(file as Asset));
    }

    private openModal(componentForTablePortraitUp: ComponentType<any>, data: FileModalData): void {
        this.modalSandbox.openModal(null, null, null, componentForTablePortraitUp, { data }, null);
    }

    public openProcessWaterAttachmentsModal(): void {
        const input = { move: ObjectUtils.cloneDeep(this.move) };
        const header = this.translateService.instant('ADMIN.DASHBOARD.DETAIL.WATER_DETAILS.LABEL') as string;
        this.modalSandbox
            .openClosableModal({
                component: WaterProcessAttachmentsModal,
                config: {
                    input,
                    header,
                    subheader: AddressUtils.buildFormattedAddress(this.move.user.movingAddress),
                    size: UiSize.Medium,
                },
            })
            .afterClosed()
            .subscribe((move) => {
                if (!move) return;
                this.form.markAsDirty();
                this.isConfirmedSubject.next(false);
                this.move = move;
            });
    }

    private modalDataFactory(file: Asset): FileModalData {
        return {
            moveId: DbUtils.getStringId(this.move),
            readOnly: true,
            file,
        };
    }
}
