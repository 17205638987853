import { Inject, Injectable } from '@angular/core';
import { BASE_URI_CONFIG, BaseUriConfig } from '@smooved/ui';
import { WaterTransferAssetType } from '@ui/water/enums/water-transfer-asset-type.enum';
import { Observable } from 'rxjs';
import { ArrayUtils, HttpUtils, StringUtils } from '@smooved/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WaterInfoResponse } from '@ui/water/interfaces/water-info-response';
import { map } from 'rxjs/operators';
import { WaterSupplier } from '@ui/water/interfaces/water-supplier';

@Injectable()
export class UiWaterService {
    constructor(
        @Inject(BASE_URI_CONFIG) private readonly baseUriConfig: BaseUriConfig,
        private readonly httpClient: HttpClient
    ) {}

    public updateWaterTransferAssets<T>(
        transactionId: string,
        assetKeysToRemove: string[],
        files: File[],
        waterTransferAssetType?: WaterTransferAssetType
    ): Observable<T> {
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file);
        });

        formData.append('assetKeysToRemove', assetKeysToRemove.join(';'));

        return this.httpClient.post<T>(`${this.baseUriConfig.apiUri}/water/transaction/${transactionId}/water-transfer-assets`, formData, {
            params: HttpUtils.buildQueryParameters({ waterTransferAssetType }),
        });
    }

    public confirmWaterMeter<T>(transactionId: string): Observable<T> {
        return this.httpClient.post<T>(
            StringUtils.parseUri(`${this.baseUriConfig.apiUri}/water/transaction/:transactionId/confirm`, { transactionId }),
            null
        );
    }

    public getWaterInfoByZipCode(zipCode: string): Observable<WaterInfoResponse[]> {
        const httpParams: HttpParams = new HttpParams().set('zipCode', zipCode);
        return this.httpClient
            .get<WaterInfoResponse[]>(`${this.baseUriConfig.apiUri}/water`, {
                params: httpParams,
            })
            .pipe(
                map((x) => {
                    return x.sort((a, b) => {
                        const valueForA = a.subMunicipality || a.municipality;
                        const valueForB = b.subMunicipality || b.municipality;
                        if (valueForA < valueForB) {
                            return -1;
                        }
                        if (valueForA > valueForB) {
                            return 1;
                        }
                        return 0;
                    });
                })
            );
    }

    public getUniqueWaterSuppliersInfoByZipCode(zipCode: string): Observable<WaterSupplier[]> {
        return this.getWaterInfoByZipCode(zipCode).pipe(
            map((response) => {
                return ArrayUtils.uniqueByProperty(response.map((waterResponse) => waterResponse.waterSuppliers || []).flat(), '_id');
            })
        );
    }
}
