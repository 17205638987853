import { Svg } from '../../icon/icon.enums';

export interface SelectInput<T> {
    id: string;
    value: T;
    icon?: string;
    svg?: Svg;
    imageUrl?: string;
    label?: string;
    labelResource?: string;
    extraInfo?: string;
    description?: string;
    descriptionResource?: string;
    name: string;
    disabled?: boolean;
    tag?: string;
    customClass?: string;
}

export interface SelectGroupInput<T> {
    id: string;
    value: T;
    name: string;
    label: string;
    description?: string;
    disabled?: boolean;
}

export enum SelectInputVariant {
    Default = 'default',
    Grouped = 'grouped',
}
