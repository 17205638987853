import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isImage } from '@app/form/utils/is-image.util';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { WaterService } from '@app/water/services/water.service';
import { dateTimeFormatDefault, DbUtils } from '@smooved/core';
import { SvgIllustration } from '@smooved/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fileExistsSync } from 'tsconfig-paths/lib/filesystem';

@Component({
    selector: 'app-water-transfer-asset-detail-modal',
    template: `
        <ng-container *ngIf="{ file: data.file.location | authImage | async } as vm">
            <app-closable-modal-template [maxWidth]="'1000px'">
                <header class="u-flex-row u-flex-align-items-start u-margin-bottom">
                    <div class="__header-content u-flex-column u-margin-right">
                        <h6>{{ data.file.name }}</h6>
                        <p class="u-color-muted">{{ data.file.createdOn | formatDate: dateTimeFormat }}</p>
                    </div>
                    <a
                        app-svg-illustration
                        id="download-file-btn"
                        [svg]="svg.Download"
                        [title]="'COMMON.DOWNLOAD.DOCUMENT' | translate"
                        class="u-cursor-pointer __download-btn"
                        [href]="vm.file"
                        [download]="data.file.name"
                    ></a>
                </header>
                <ngx-extended-pdf-viewer
                    *ngIf="isImage() == false; else image"
                    [zoom]="100"
                    [showToolbar]="false"
                    [src]="pdfSrc$ | async"
                    style="display: block; width: 900px;"
                ></ngx-extended-pdf-viewer>
            </app-closable-modal-template>

            <ng-template #image>
                <img [src]="vm.file" class="u-mw100p" />
            </ng-template>
        </ng-container>
    `,
    styleUrls: ['water-transfer-asset-detail.component.scss'],
})
export class WaterTransferAssetDetailComponent implements OnInit {
    public pdfSrc$: Observable<string>;

    public readonly dateTimeFormat = dateTimeFormatDefault;
    public readonly svg = SvgIllustration;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FileModalData,
        private http: HttpClient
    ) {}

    public isImage(): boolean {
        return isImage(this.data.file);
    }

    public ngOnInit(): void {
        this.pdfSrc$ = this.http
            .get(`${WaterService.baseUri}/transaction/${this.data.moveId}/water-transfer-asset/${DbUtils.getStringId(this.data.file)}`, {
                responseType: 'arraybuffer',
            })
            .pipe(
                map((data) => {
                    const blob = new Blob([data], { type: 'application/pdf' });
                    return URL.createObjectURL(blob);
                })
            );
    }
}
