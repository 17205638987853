import { WaterSupplier } from '@ui/water/interfaces/water-supplier';
import { getWaterSuppliersSuccess } from '@app/water/state/water.actions';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

export interface WaterState {
    waterSuppliers: WaterSupplier[];
}

export const initialState: WaterState = {
    waterSuppliers: [],
};

export const waterReducer = createReducer(
    initialState,
    on(getWaterSuppliersSuccess, (state, { waterSuppliers }) => ({
        ...state,
        waterSuppliers,
    }))
);

export const getWaterState = createFeatureSelector<WaterState>('water');
export const getWaterSuppliers = createSelector(getWaterState, (state) => state.waterSuppliers);
