import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';

@Component({
    selector: 'app-energy-suggestions-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center u-no-padding-left u-no-padding-right">
            <div class="u-w100p">
                <h3 class="u-margin-bottom u-padding-left-container">
                    {{ 'REAL_ESTATE_AGENT.EOTS.ENERGY_SUGGESTIONS.TITLE' | translate }}
                </h3>
                <app-energy-suggestions (previous)="goToPrevious()" (energySuggestionChange)="onChange($event)"></app-energy-suggestions>
            </div>
        </app-card-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergySuggestionsContainer {
    constructor(
        private router: Router,
        private moveSandbox: MoveSandbox,
        private route: ActivatedRoute
    ) {}

    public goToPrevious(): void {
        this.moveSandbox.moveOnce$.subscribe((moveState) => {
            let path: string;
            if (moveState && moveState.energyOffer && (moveState.energyOffer.energyType as unknown) === EnergyType.Gas) {
                path = '../data/energy-type';
            } else {
                path = '../data/meter-type';
            }
            this.router
                .navigate([path], {
                    relativeTo: this.route,
                })
                .then();
        });
    }

    public onChange(energySuggestion: EnergySuggestion): void {
        const payload: Move = {
            energySelected: true,
            energyOffer: {
                energyOfferSelection: {
                    electricityTotalPriceInclusivePromotions: null,
                    gasTotalPriceInclusivePromotions: null,
                    electricityGasTotalPriceInclusivePromotions: null,
                    totalPriceInclusivePromotions: null,
                    ...energySuggestion,
                },
            },
        };

        this.moveSandbox.patchProperty('', payload, true, () => {
            this.moveSandbox.telecomOfferOrTransferSelectedOnce$.subscribe((telecomOfferOrTransferSelected) => {
                if (telecomOfferOrTransferSelected) {
                    this.router.navigateByUrl(`/${Navigation.Contract}/check`).then();
                } else {
                    this.router.navigateByUrl(`/${Navigation.Energy}/offer-selected`).then();
                }
            });
        });
    }
}
