<app-card-container>
    <div class="u-container u-margin-0-auto">
        <app-card class="u-flex-column u-flex-align-items-start">
            <h3 class="u-margin-bottom">{{ 'WATER.LOOKUP_TOOL.TITLE' | translate }}</h3>
            <div class="u-flex-row u-flex-align-items-center">
                <smvd-ui-text-input
                    [formControl]="zipCodeControl"
                    width="auto"
                    [placeholder]="'UI.ADDRESS.ZIP_CODE' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    class="u-margin-right"
                ></smvd-ui-text-input>

                <app-loading-container [loading]="loading">
                    <smvd-ui-dropdown-input [formControl]="subMunicipalityControl" [hasMargin]="false"
                                            [options]="subMunicipalityOptions"
                                            [hasMarginDouble]="false"></smvd-ui-dropdown-input>
                </app-loading-container>
            </div>
            <ng-container *ngIf="subMunicipalityControl.value?.waterSuppliers as waterSuppliers">
                <app-water-supplier *ngFor="let waterSupplier of waterSuppliers"
                                    class="u-display-block u-margin-top-double"
                                    [waterSupplier]="waterSupplier"></app-water-supplier>
            </ng-container>

        </app-card>
    </div>
</app-card-container>
