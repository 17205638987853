import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileInput } from '@app/form/interfaces/file-input';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { WaterTransferAssetDetailComponent } from '@app/real-estate-agent/modals/water-transfer-asset-detail/water-transfer-asset-detail.component';
import { WaterTransferAssetType } from '@ui/water/enums/water-transfer-asset-type.enum';
import { WaterTransferAsset } from '@app/water/interfaces/water';
import { WaterService } from '@app/water/services/water.service';
import { ALLOWED_MIME_TYPES, ArrayUtils, Asset, DbUtils, FileUtils, MAX_FILE_SIZE, Mimetypes } from '@smooved/core';
import { ButtonAppearance, ModalSandbox, NotificationSandbox, SvgIllustration, UiContext, UiIcon } from '@smooved/ui';
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'water-process-attachments-modal',
    templateUrl: './water-process-attachments.modal.html',
    styleUrls: ['./water-process-attachments.modal.scss'],
})
export class WaterProcessAttachmentsModal implements OnInit {
    protected readonly WaterTransferAssetType = WaterTransferAssetType;
    protected readonly ALLOWED_MIME_TYPES = ALLOWED_MIME_TYPES;
    protected readonly uiIcon = UiIcon;
    protected readonly SvgIllustration = SvgIllustration;
    protected readonly buttonAppearance = ButtonAppearance;
    protected readonly svgIllustration = SvgIllustration;
    protected readonly illustrationEnum = SvgIllustration;
    protected readonly Mimetypes = Mimetypes;
    protected readonly UiContext = UiContext;

    constructor(
        @Inject(MAT_DIALOG_DATA) public move: Move,
        public dialogRef: MatDialogRef<any>,
        protected moveSandbox: MoveSandbox,
        private readonly notificationSandbox: NotificationSandbox,
        private readonly waterService: WaterService,
        private readonly modalSandbox: ModalSandbox
    ) {}

    public loading: boolean;
    public uploader: FileUploader;

    private addedAssetKeys: string[] = [];

    public ngOnInit(): void {
        this.uploader = FileUtils.getFileUploader();

        this.dialogRef.afterClosed().subscribe((move) => {
            if (!move && !ArrayUtils.isEmpty(this.addedAssetKeys)) {
                this.waterService.updateWaterTransferAssets(DbUtils.getStringId(this.move), this.addedAssetKeys, []).subscribe();
            }
        });
    }

    public toggleAssetType(waterTransferAsset: WaterTransferAsset, waterTransferAssetType: WaterTransferAssetType): void {
        const transitions: Record<WaterTransferAssetType, Partial<Record<WaterTransferAssetType, WaterTransferAssetType>>> = {
            [WaterTransferAssetType.Attachment]: {
                [WaterTransferAssetType.EmailAttachment]: WaterTransferAssetType.EmailAttachment,
                [WaterTransferAssetType.TransferDocument]: WaterTransferAssetType.TransferDocument,
            },
            [WaterTransferAssetType.EmailAttachment]: {
                [WaterTransferAssetType.EmailAttachment]: WaterTransferAssetType.Attachment,
                [WaterTransferAssetType.TransferDocument]: WaterTransferAssetType.TransferDocument,
            },
            [WaterTransferAssetType.TransferDocument]: {
                [WaterTransferAssetType.EmailAttachment]: WaterTransferAssetType.EmailAttachment,
                [WaterTransferAssetType.TransferDocument]: WaterTransferAssetType.Attachment,
            },
        };
        const newType = transitions[waterTransferAsset.waterTransferAssetType]?.[waterTransferAssetType];

        waterTransferAsset.waterTransferAssetType = newType !== undefined ? newType : waterTransferAsset.waterTransferAssetType;
    }

    public onFileSelected(fileList: FileList): void {
        const list = Array.from(fileList);
        const maxSize = list.find((file) => file.size > FileUtils.convertMegaBytesToBytes(MAX_FILE_SIZE));
        if (maxSize) {
            this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.SIZE', { maxFileSize: MAX_FILE_SIZE });
            return;
        }

        const noAllowedType = list.find((file) => !ALLOWED_MIME_TYPES.includes(file.type as Mimetypes));
        if (noAllowedType) {
            this.notificationSandbox.error('ENERGY.INPUT.FILE_ERROR.TYPE', {
                allowedTypes: ALLOWED_MIME_TYPES.map((mime) => ArrayUtils.last(mime.split('/'))).join(', '),
            });
            return;
        }

        this.loading = true;
        const assetTypeMapping = this.getAssetTypeMapping();
        this.waterService
            .updateWaterTransferAssets(DbUtils.getStringId(this.move), [], list, WaterTransferAssetType.EmailAttachment)
            .subscribe((updatedMove) => {
                this.setAssetTypeMapping(updatedMove, assetTypeMapping);
                this.move = updatedMove;
                list.forEach((file) =>
                    this.addedAssetKeys.push(updatedMove.water.waterTransferAssets.find((asset) => asset.name === file.name).key)
                );
                this.loading = false;
            });
    }

    public onGenerateWaterTransferDocument(): void {
        this.loading = true;
        const assetTypeMapping = this.getAssetTypeMapping();
        this.waterService.generateWaterTransferDocument(DbUtils.getStringId(this.move)).subscribe({
            next: (updatedMove) => {
                this.setAssetTypeMapping(updatedMove, assetTypeMapping);
                this.move = updatedMove;
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
            },
        });
    }

    public onWaterTransferAssetDetail(file: FileInput): void {
        this.modalSandbox.openModal(
            null,
            null,
            null,
            WaterTransferAssetDetailComponent,
            {
                data: this.modalDataFactory(file as Asset),
            },
            null
        );
    }

    private modalDataFactory(file: Asset): FileModalData {
        return {
            moveId: DbUtils.getStringId(this.move),
            readOnly: true,
            file,
        };
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public saveAssetTypeMapping(): void {
        this.loading = true;
        const assetTypeMapping = this.getAssetTypeMapping();

        this.waterService.setWaterTransferAssetTypes(DbUtils.getStringId(this.move), assetTypeMapping).subscribe((updatedMove) => {
            this.loading = false;
            this.dialogRef.close(updatedMove);
        });
    }

    private getAssetTypeMapping(): { [key: string]: WaterTransferAssetType } {
        return this.move?.water?.waterTransferAssets?.reduce((acc, waterTransferAsset) => {
            acc[DbUtils.getStringId(waterTransferAsset)] = waterTransferAsset.waterTransferAssetType;
            return acc;
        }, {});
    }

    private setAssetTypeMapping(move: Move, assetTypeMapping: { [key: string]: WaterTransferAssetType }): void {
        move.water.waterTransferAssets = move.water.waterTransferAssets.map((waterTransferAsset) => {
            waterTransferAsset.waterTransferAssetType =
                assetTypeMapping[DbUtils.getStringId(waterTransferAsset)] ?? waterTransferAsset.waterTransferAssetType;
            return waterTransferAsset;
        });
    }
}
