import { WaterSupplier } from '@ui/water/interfaces/water-supplier';
import { createAction, props } from '@ngrx/store';

export enum WaterActionTypes {
    GetWaterSuppliers = '[Water] Get water suppliers',
    GetWaterSuppliersSuccess = '[Water] Get water suppliers success',
    GetWaterSuppliersFailure = '[Water] Get water suppliers failure',
}

export const getWaterSuppliersAction = createAction(WaterActionTypes.GetWaterSuppliers);
export const getWaterSuppliersSuccess = createAction(
    WaterActionTypes.GetWaterSuppliersSuccess,
    props<{
        waterSuppliers: WaterSupplier[];
    }>()
);
export const getWaterSuppliersFailure = createAction(WaterActionTypes.GetWaterSuppliersFailure);
