import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { WaterSupplier } from '@ui/water/interfaces/water-supplier';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { WaterTransferAssetType } from '@ui/water/enums/water-transfer-asset-type.enum';
import { UiWaterService } from '@ui/water/services/water.service';

@Injectable({
    providedIn: 'root',
})
export class WaterService {
    public static readonly baseUri: string = `${environment.apiUri}/water`;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly uiWaterService: UiWaterService
    ) {}

    public getWaterSuppliers(): Observable<WaterSupplier[]> {
        return this.httpClient.get<WaterSupplier[]>(`${WaterService.baseUri}/water-suppliers`);
    }

    public confirmWaterProcess(transactionId: string): Observable<Move> {
        return this.uiWaterService.confirmWaterMeter<Move>(transactionId);
    }

    public updateWaterTransferAssets(
        transactionId: string,
        assetKeysToRemove: string[],
        files: File[],
        waterTransferAssetType?: WaterTransferAssetType
    ): Observable<Move> {
        return this.uiWaterService.updateWaterTransferAssets<Move>(transactionId, assetKeysToRemove, files, waterTransferAssetType);
    }

    public generateWaterTransferDocument(transactionId: string): Observable<Move> {
        return this.httpClient.post<Move>(`${WaterService.baseUri}/transaction/${transactionId}/generate-water-transfer-document`, null);
    }

    public setWaterTransferAssetTypes(
        transactionId: string,
        assetTypeMapping: {
            [id: string]: WaterTransferAssetType;
        }
    ): Observable<Move> {
        return this.httpClient.post<Move>(`${WaterService.baseUri}/transaction/${transactionId}/water-transfer-asset-types`, {
            assetTypeMapping,
        });
    }

    public getWaterTransferAsset(transactionId: string, assetId: string): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${WaterService.baseUri}/transaction/${transactionId}/water-transfer-asset/${assetId}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }
}
