import { Move } from '@app/move/interfaces/move';
import { MoveForLeaver } from '@app/wizard/move/interfaces/move-for-leaver';
import { Action, createAction, props } from '@ngrx/store';
import { ErrorResponse } from '@smooved/core';
import { CreateMoveOptions } from '../interfaces/create-move-options';
import { DeleteMoveOptionsRequest } from '../interfaces/delete-move-options.request';

export enum MoveActionTypes {
    FetchMove = '[Move] Fetch move',
    SetMoveState = '[Move] Set move state',
    PatchMoveState = '[Move] Patch move state',
    PatchRealEstateAgent = '[Move] Patch real estate agent',
    DontPatchMove = "[Move] Dont't patch move",
    PatchMove = '[Move] Patch move',
    PatchMoveSuccess = '[Move] Patch move success',
    PatchMoveFailure = '[Move] Patch move failure',
    SetLatestMoveState = '[Move] Set latest move state',
    CreateMove = '[Move] Create move',
    CreateMoveSuccess = '[Move] Create move success',
    CreateMoveFailure = '[Move] Create move failure',
    CreateMoveForLeaver = '[Move] Create move for leaver',
    CreateMoveForLeaverSuccess = '[Move] Create move for leaver success',
    CreateMoveForLeaverFailure = '[Move] Create move for leaver failure',

    GetMoveByAccessTokenAndId = '[Move] Get move by access token and id',
    GetMoveByAccessTokenAndIdSuccess = '[Move] Get move by access token and id success',
    GetMoveByAccessTokenAndIdFailure = '[Move] Get move by access token and id failure',

    Confirm = '[Move] Confirm',
    ConfirmSuccess = '[Move] Confirm success',
    ConfirmFailure = '[Move] Confirm failure',

    ConfirmOffers = '[Move] Confirm offers',
    ConfirmOffersSuccess = '[Move] Confirm offers success',
    ConfirmOffersFailure = '[Move] Confirm offers failure',

    ConfirmEnergy = '[Move] Confirm energy',
    ConfirmEnergySuccess = '[Move] Confirm energy success',
    ConfirmEnergyFailure = '[Move] Confirm energy failure',

    PatchProperty = '[Move] Patch property',
    PatchPropertySuccess = '[Move] Patch property success',
    PatchPropertyFailure = '[Move] Patch property failure',

    DeleteMove = '[Move] Delete move',

    EnergyMeterReadingsAttachmentUploaded = '[Move] Energy meter readings attachment uploaded',
    EnergyMeterReadingsManual = '[Move] Energy meter readings manual',
    EnergyMeterReadingsConfirmed = '[Move] Energy meter readings confirmed',
}

export class FetchMove implements Action {
    public readonly type = MoveActionTypes.FetchMove;

    constructor(public payload: { moveId: string; callback?: (move: Move) => any }) {}
}

export class SetMoveState implements Action {
    public readonly type = MoveActionTypes.SetMoveState;

    constructor(public payload: { move: Move }) {}
}

export class PatchMoveState implements Action {
    public readonly type = MoveActionTypes.PatchMoveState;

    constructor(public payload: { rootPath: string; propertyPath: string; value: any }) {}
}

export class PatchRealEstateAgent implements Action {
    public readonly type = MoveActionTypes.PatchRealEstateAgent;

    constructor(public payload: string) {}
}

export class DontPatchMove implements Action {
    public readonly type = MoveActionTypes.DontPatchMove;
}

export class PatchMove implements Action {
    public readonly type = MoveActionTypes.PatchMove;

    constructor(
        public payload: {
            id: string;
            patch: Move;
            withNotification: boolean;
            dialogIdToClose?: string;
            callback?: (move: Move) => any;
            forceNull?: boolean;
            onFailure?: () => any;
        }
    ) {}
}

export class PatchMoveSuccess implements Action {
    public readonly type = MoveActionTypes.PatchMoveSuccess;
}

export const PatchMoveFailure = createAction(
    MoveActionTypes.PatchMoveFailure,
    props<{
        error: ErrorResponse;
        transactionId: string;
        request: any;
    }>()
);

export class SetLatestMoveState implements Action {
    public readonly type = MoveActionTypes.SetLatestMoveState;

    constructor(public payload: { move: Move }) {}
}

export class CreateMove implements Action {
    public readonly type = MoveActionTypes.CreateMove;

    constructor(public payload: { move: Move; options?: CreateMoveOptions }) {}
}

export class CreateMoveSuccess implements Action {
    public readonly type = MoveActionTypes.CreateMoveSuccess;

    constructor(public payload?: { id: string; options?: CreateMoveOptions }) {}
}

export class CreateMoveFailure implements Action {
    public readonly type = MoveActionTypes.CreateMoveFailure;

    constructor(public payload: { error: ErrorResponse; move: Move }) {}
}

export class CreateMoveForLeaver implements Action {
    public readonly type = MoveActionTypes.CreateMoveForLeaver;

    constructor(public payload: { move: MoveForLeaver; callback?: () => any }) {}
}

export class CreateMoveForLeaverSuccess implements Action {
    public readonly type = MoveActionTypes.CreateMoveForLeaverSuccess;

    constructor(public payload?: { id: string; callback?: () => any }) {}
}

export class CreateMoveForLeaverFailure implements Action {
    public readonly type = MoveActionTypes.CreateMoveForLeaverFailure;
}

export class Confirm implements Action {
    public readonly type = MoveActionTypes.Confirm;

    constructor(public payload: { id: string; accessToken?: string; callback?: () => any }) {}
}

export class ConfirmSuccess implements Action {
    public readonly type = MoveActionTypes.ConfirmSuccess;

    constructor(public payload?: { callback?: () => any; move?: Move }) {}
}

export class ConfirmFailure implements Action {
    public readonly type = MoveActionTypes.ConfirmFailure;
}

export class ConfirmOffers implements Action {
    public readonly type = MoveActionTypes.ConfirmOffers;

    constructor(public payload: { id: string; accessToken?: string; callback?: () => any }) {}
}

export class ConfirmOffersSuccess implements Action {
    public readonly type = MoveActionTypes.ConfirmOffersSuccess;

    constructor(public payload?: { callback?: () => any }) {}
}

export class ConfirmOffersFailure implements Action {
    public readonly type = MoveActionTypes.ConfirmOffersFailure;
}

export class ConfirmEnergy implements Action {
    public readonly type = MoveActionTypes.ConfirmEnergy;

    constructor(public payload: { accessToken: string; id: string; callback?: () => any }) {}
}

export class ConfirmEnergySuccess implements Action {
    public readonly type = MoveActionTypes.ConfirmEnergySuccess;

    constructor(public payload?: { callback?: () => any }) {}
}

export class ConfirmEnergyFailure implements Action {
    public readonly type = MoveActionTypes.ConfirmEnergyFailure;
}

export class DeleteMove implements Action {
    public readonly type = MoveActionTypes.DeleteMove;

    constructor(public payload: { id: string; options: DeleteMoveOptionsRequest; callback?: () => any }) {}
}

export class PatchProperty implements Action {
    public readonly type = MoveActionTypes.PatchProperty;

    constructor(
        public payload: {
            propertyPath: string;
            value: any;
            persist: boolean;
            callback: (move: Move) => any;
            checkMoveWithLatestState?: boolean;
            moveToPatch?: Move;
            persistToState?: boolean;
            showLoadingOverlay?: boolean;
            bypassIsDraft?: boolean;
        }
    ) {}
}

export class PatchPropertySuccess implements Action {
    public readonly type = MoveActionTypes.PatchPropertySuccess;

    constructor(
        public payload?: {
            newState?: any;
            callback?: (move: Move) => any;
            persistToState?: boolean;
        }
    ) {}
}

export const PatchPropertyFailure = createAction(
    MoveActionTypes.PatchPropertyFailure,
    props<{
        error: ErrorResponse;
        transactionId: string;
        request: any;
    }>()
);

export class GetMoveByAccessTokenAndId implements Action {
    public readonly type = MoveActionTypes.GetMoveByAccessTokenAndId;

    constructor(public payload: { accessToken: string; id: string; loadingIndication: boolean; callback?: () => any }) {}
}

export class GetMoveByAccessTokenAndIdSuccess implements Action {
    public readonly type = MoveActionTypes.GetMoveByAccessTokenAndIdSuccess;

    constructor(public payload?: { move: Move; callback?: () => any }) {}
}

export class GetMoveByAccessTokenAndIdFailure implements Action {
    public readonly type = MoveActionTypes.GetMoveByAccessTokenAndIdFailure;

    constructor(public payload?: { callback?: () => any }) {}
}

export const energyMeterReadingsAttachmentUploaded = createAction(
    MoveActionTypes.EnergyMeterReadingsAttachmentUploaded,
    props<{ moveId: string }>()
);

export const energyMeterReadingsManual = createAction(
    MoveActionTypes.EnergyMeterReadingsManual,
    props<{
        moveId: string;
    }>()
);

export const energyMeterReadingsConfirmed = createAction(
    MoveActionTypes.EnergyMeterReadingsConfirmed,
    props<{
        moveId: string;
    }>()
);

export type MoveActions =
    | FetchMove
    | SetMoveState
    | PatchMoveState
    | PatchRealEstateAgent
    | DontPatchMove
    | PatchMove
    | PatchMoveSuccess
    | SetLatestMoveState
    | CreateMove
    | CreateMoveSuccess
    | CreateMoveFailure
    | CreateMoveForLeaver
    | CreateMoveForLeaverSuccess
    | CreateMoveForLeaverFailure
    | Confirm
    | ConfirmSuccess
    | ConfirmFailure
    | ConfirmOffers
    | ConfirmOffersSuccess
    | ConfirmOffersFailure
    | ConfirmEnergy
    | ConfirmEnergySuccess
    | ConfirmEnergyFailure
    | PatchProperty
    | PatchPropertySuccess
    | GetMoveByAccessTokenAndId
    | GetMoveByAccessTokenAndIdSuccess
    | GetMoveByAccessTokenAndIdFailure
    | DeleteMove;
