/* **************************************************************************
 *
 *  WARNING: DO NOT RENAME -> ENUMS MUST BE KEPT IN SYNC WITH POSTHOG
 *
 * ***************************************************************************/

export enum PostHogEventsEnum {
    CompanionEnergyMetersEditAttachmentUploaded = 'CompanionEnergyMetersEditAttachmentUploaded',
    CompanionEnergyMetersEditManual = 'CompanionEnergyMetersEditManual',
    CompanionEnergyMetersConfirmed = 'CompanionEnergyMetersConfirmed',
    DesktopEnergyMetersEditAttachmentUploaded = 'DesktopEnergyMetersEditAttachmentUploaded',
    DesktopEnergyMetersEditManual = 'DesktopEnergyMetersEditManual',
    DesktopEnergyMetersConfirmed = 'DesktopEnergyMetersConfirmed',

    /** Water Transfer */
    SaveWaterHandling = 'Save Water Handling',
    UpdateTransferDate = 'Update Transfer Date',
    SaveOnlyWaterInfo = 'Save Only Water Info',
    SaveAndConfirmWaterInfo = 'Save and Confirm Water Info',
    ConfirmOnlyWaterInfo = 'Confirm Only Water Info',
}
