import { Pipe, PipeTransform } from '@angular/core';
import { WaterTransferAssetType } from '@ui/water/enums/water-transfer-asset-type.enum';
import { ArrayUtils } from '@smooved/core';
import { WaterTransferAsset } from '@app/water/interfaces/water';

@Pipe({
    name: 'waterTransferAssetFilter',
    pure: false,
})
export class WaterTransferAssetFilterPipe implements PipeTransform {
    transform(assets: WaterTransferAsset[], types: WaterTransferAssetType[]): WaterTransferAsset[] {
        if (!assets || ArrayUtils.isEmpty(types)) {
            return assets;
        }
        return assets.filter((asset) => types.some((type) => type === asset.waterTransferAssetType));
    }
}
